@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.DonationPage {
  min-height: 78vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__card-list {
    @include tablet {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
}
