@import "../../../Partials/variables";
@import "../../../Partials/mixins";
@import "../../../Partials/variables";

.waterfowl {
  position: relative;
  @include perfectHeight;
  &__back{
    @include bluredBackground;
  }
  &__first-wrapper{
    position: absolute; 
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  &__wrapper {
    margin: 0 auto;
    padding: 4rem 1rem 1rem;
    max-width: 1400px;
    transition: 0.4s;
    @include tablet {
      padding-top: 0;
    }
  }
}
