@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.aniaml-identification{
    margin-top: 2rem;
    @include animalCardStyle;
    @include tablet{
        padding: 2rem 4rem;
    }
    &__title{
        margin-top: 0;
        margin-bottom: 0;
        @include tablet{
            margin-bottom: 1rem;
            font-size: 2rem;
        }
    }
    &__wrapper{
        @include tablet{
            margin: 0 auto;
            max-width: 1000px;
        }
    }
}