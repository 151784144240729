@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.StripeForm {
  padding: 0 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &__input{
      margin-bottom: 1rem;
      width: 100%;
  }
  &__btn {
    font-size: 1rem;
    border: 2px solid black;
    border-radius: 4px;
    padding: .2rem .4rem;
    background-color: white;
    box-shadow: 4px 4px 4px #ccc;
  }
}
