@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.ThankYou {
  min-height: 78vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title {
    font-size: 2.8rem;
  }
  &__p {
    font-size: 1.2rem;
  }
  &__wrapper {
    display: flex;
    align-items: center;
  }
  &__success {
    display: none;
    @include tablet {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 1rem;
      width: 50px;
      color: white;
      height: 50px;
      text-align: center;
      background: green;
      border-radius: 50%;
      font-size: 3rem;
    }
  }
}
