@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.find-wmu{
    padding: 1rem;
    max-width: 1200px;
    display: block;
    margin: 0 auto;
    @include perfectHeight;
    &__title{
        margin-top: 0;
    }
    &__label{
        font-size: 1.05rem;
    }
    &__input{
        margin-top: .2rem;
        width: 100%;
        padding: .4rem;
        font-size: 1rem;
        border: 2px solid black;
        border-radius: 8px;
        box-shadow: 10px 10px 10px #ddd;
    }
    &__info-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__table{
        text-align: center;
        width: 100%;
        margin-top: 1rem;
        transition: .4s;
        @include tablet{
            margin-top: 1.2rem;
        }
        @include desktop{
            margin-top: 2rem;
        }
        &-head{
            font-weight: 600;
        }
    }
}