@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.DonationCard {
  border: 4px solid green;
  border-radius: 8px;
  background-color: white;
  margin: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 4px 4px 4px #ccc;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.4s;
  position: relative;
  @include tablet{
    width: 29%;
    margin: 0 0 1rem 0;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 1rem 1rem 1rem #ccc;
  }
  &__title {
    margin: 0;
    z-index: 2;
    font-size: 1.2rem;
  }
  &__message {
    margin: 0;
    z-index: 2;
  }
  &__input {
    display: none;
    &:checked + .DonationCard__back {
      background-color: lightgreen;
    }
    &:checked {}
  }
  &__back {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    transition: 0.4s;
  }
}
