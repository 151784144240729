@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 100vh;
  width: 100vw;
  top: auto;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .4s;
  padding-top: calc(50vh - 200px);
  @include tablet {
    padding-top: calc(50vh - 250px);
  }
}
