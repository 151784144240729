@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.aniaml-identification-cards{
    border-bottom: 1px solid black;
    padding: 1rem;
    &__image{
        width: 100%;
        align-self: center;
        border: 2px solid black;
        @include tablet{
            margin-left: 1rem;
            width: 200px;
        }
    }
    &__wrapper{
        @include tablet{
            display: flex;
            justify-content: space-between;
        }
    }
    &__title{
        @include tablet{
            font-size: 1.5rem;
        }
    }
    &__info{
        @include tablet{
            font-size: 1.2rem;
        }
    }
}