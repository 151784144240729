*,
*::before,
*::after {
  box-sizing: border-box;
}

@mixin tablet {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin centerFlexCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin animalCardStyle {
  background-color: white;
  padding: 1rem 0.5rem;
  border-radius: 8px;
  box-shadow: -2px 2px 2px 2px #888;
}

@mixin normalCenterMain {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin bluredBackground {
  height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: -1;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

@mixin perfectHeight {
  min-height: calc(100vh - 166px);
  @media (min-width: $tablet) {
    min-height: calc(100vh - 203px);
  }
  @media (min-width: $desktop) {
    min-height: calc(100vh - 241px);
  }
}
