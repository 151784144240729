@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.Header {
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("../../Images/GeneralWebsiteArt/landscape-trees-forest.jpg");
  background-blend-mode: multiply;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid white;
    @include tablet {
      justify-content: space-around;
    }
    &-link {
      color: white;
      text-decoration: none;
      font-weight: 200;
      font-size: 1rem;
      padding: 0.5rem 0.2rem;
      transition: 0.5s;
      &:hover {
        background-color: #d1d1d1;
      }
      @include tablet {
        font-size: 1.2rem;
        font-weight: 200;
        padding: 0.5rem 1rem;
      }
      @include desktop {
        font-size: 1.5rem;
      }
    }
  }
  &__title {
    color: white;
    margin: 0.5rem 0;
    transition: 0.4s;
    font-family: wilderness;
    font-weight: 100;
    font-size: 3rem;
    @include tablet {
      font-size: 4rem;
    }
    @include desktop {
      font-size: 5rem;
    }
  }
}
