@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.hunting-method-card{
    margin-bottom: 3rem;
    @include desktop{
        display: flex;
        justify-content: space-between;
    }
    &__image{
        align-self: center;
        width: 100%;
        @include tablet{
            display: block;
            margin: 1rem auto 0;
            max-width: 300px;
        }
        @include desktop{
            margin: 0;
            margin-left: 2rem;
        }
    }
    &__title{
        margin: 0;
        @include tablet{
            margin-top: 1rem;
            font-size: 1.4rem;
        }
    }
    &__info{
        @include tablet{
            font-size: 1.2rem;
            margin-bottom: 0;
        }
    }
}