@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.NotFoundPage {
  min-height: 77vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__text {
    transition: 0.4s;
    font-size: 2.5rem;
    font-family: wilderness;
    @include tablet {
      font-size: 4rem;
    }
  }
  &__btn {
    transition: 0.4s;
    font-family: wilderness;
    color: white;
    font-size: 2rem;
    background-color: green;
    background-image: linear-gradient(315deg, green 0%, #2d9600 74%);
    border: 2px solid black;
    border-radius: 0.6rem;
    padding: 0.2rem 2rem;
    box-shadow: 2px 2px 2px #888;
    &:hover {
      border: 4px solid black;
      font-size: 2.2rem;
    }
  }
}
