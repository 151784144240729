@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.aniaml-gear-card {
  &__img {
    width: 70%;
    margin-top: 1rem;
    max-width: 300px;
    
    align-self: center;
    @include tablet {
      width: 100%;
    }
  }
  &__title {
    @include tablet {
      font-size: 1.4rem;
    }
  }
  &__info {
    @include tablet {
      font-size: 1.2rem;
    }
  }
  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}
