@import "../../../Partials/variables";
@import "../../../Partials/mixins";
@import "../../../Partials/fonts";

.bass {
  position: relative;
  @include perfectHeight;
  &__wrapper {
    margin: 0 auto;
    padding: 4rem 1rem 1rem;
    max-width: 1400px;
    transition: 0.4s;
    @include tablet {
      padding-top: 0;
    }
  }
}