@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.animal-header {
  margin-top: 1rem;
  @include animalCardStyle;
  @include tablet {
    margin-top: 2rem;
    padding: 2rem 4rem;
  }
  &__title {
    margin-top: 0;
    font-size: 1.5rem;
    @include tablet {
      font-size: 2rem;
    }
  }
  &__intro {
    @include tablet {
      font-size: 1.2rem;
    }
  }
}
