@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.animal-behaviour {
    margin-top: 2rem;
    @include animalCardStyle;
    @include tablet {
      padding: 2rem 4rem;
    }
    &__title {
      margin-top: 0;
      @include tablet {
        font-size: 2rem;
      }
    }
    &__card {
      &__title {
        margin-top: 0;
        margin-bottom: .5rem;
        @include tablet {
          font-size: 1.5rem;
        }
      }
      &__info {
          margin: 0;
          margin-bottom: 1rem;
        @include tablet {
          font-size: 1.2rem;
        }
      }
    }
  }
  