@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";


.fishingNavigationBar {
  position: absolute;
  height: 100%;
  width: 100%;

  @include tablet{
    position: relative;
  }

  &__drop-lines {
    position: relative;
    width: 100%;
    @include centerFlexCol;
    align-items: flex-end;
    justify-content: flex-start;
    padding-right: 2rem;
    padding-top: 1rem;
    @include tablet {
      display: none;
    }
  }
  &__wrapper{
    height: 100%;
  }
  &__drop-line {
    background-color: black;
    margin-top: 0.4rem;
    width: 10px;
    height: 10px;
    border-radius: 2rem;
  }
  
  &__drop-list {
    width: 100%;
    margin-top: 1rem;
    border-bottom: 1px solid black;
    position: absolute;
    @include centerFlexCol;
    @include tablet {
      position: relative;
      border-bottom: 0;
      width: 100%;
      margin-top: 0;
      display: flex !important;
      justify-content: space-evenly;
      flex-direction: row;
    }
  }
  &__link {
    width: 100%;
    padding: 0.5rem 0.2rem;
    display: flex;
    align-items: center;
    border: 1px solid black;
    border-bottom: none;
    background-color: rgba(60, 80, 60, .85);
    transition: .4s;
    @include tablet {
      margin: 0;
      border: none;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      &:hover {
        border-bottom: 1px solid #888;
        border-right: 1px solid #888;
      }
    }
    @include desktop {
      padding: 0.5rem 1rem;
    }
    &:hover {
      background-color: rgba(190, 250, 190, 0.7);
      .animalNavigationBar__title {
        color: #222;
      }
    }
  }
  &__title {
    transition: .4s;
    margin: 0.2rem;
    color: white;
  }
  &__img {
    border-radius: 10rem;
    width: 50px;
    height: 50px;
    box-shadow: 2px 2px 2px #888;
  }
}