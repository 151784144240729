@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.aniaml-activity{
    @include animalCardStyle;
    margin-top: 2rem;
    @include tablet{
        padding: 2rem 4rem;
    }
    &__title{
        margin-top: 0;
        @include tablet{
            font-size: 2rem;
        }
    }
    &__wrapper{
        max-width: 1000px;
        margin: 0 auto;
    }
}