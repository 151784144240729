@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.public-hunting {
    min-height: 78vh;
    padding-top: 0;
    padding: 1rem;
    &__list{
        list-style-type: none;
        padding: 0;
        @include tablet{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }
        &-item{
            border: 4px solid black;
            border-radius: 8px;
            margin-bottom: 1rem;
            padding: 1rem;
            transition: .4s;
            &:hover{
                cursor: pointer;
                box-shadow: 8px 8px 8px 8px #bbb;
            }
            @include tablet{
                width: 48%;
            }
            @include desktop{
                width: 350px;
            }
            &-title{
                margin-top: 0;
            }
            &-p{
                margin: .2rem 0 0;
            }
        }
    }
    &__search {
        width: 80%;
        padding: .2rem;
        font-size: 1.2rem;
        border-radius: 4px;
        border: solid 2px black;
        max-width: 600px;
    }
}