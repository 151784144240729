@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.animal-calling-list{
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    &__info{
        @include tablet{
            font-size: 1.2rem;
        }
    }
}