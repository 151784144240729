@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.AnimalSensesCard{
    @include tablet{
        display: flex;
        align-items: center;
    }
    &__title{
        margin: 0;
        @include tablet{
            margin-top: 1rem;
            font-size: 1.4rem;
        }
    }
    &__info{
        @include tablet{
            font-size: 1.2rem;
            margin-bottom: 0;
        }
    }
    &__image{
        height: 125px;
    }
    &__image-wrapper{
        width: 125px;
        min-width: 125px;
        display: flex;
        justify-content: center;
    }
}