@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.animal-activity-card {
  &__image {
    width: 100%;
    max-width: 400px;
    align-self: center;
    @include tablet {
      width: 200px;
      margin-left: 1rem;
    }
    @include desktop {
      margin-left: 3rem;
      width: 300px;
      min-width: 300px;
    }
  }
  &__title {
    @include tablet {
      font-size: 1.5rem;
    }
  }
  &__info {
    @include tablet {
      font-size: 1.2rem;
    }
  }
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
  @include tablet {
    display: flex;
    justify-content: space-between;
  }
}
