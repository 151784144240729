@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.orange {
  margin-top: 2rem;
  @include animalCardStyle;
  @include tablet {
    padding: 2rem 4rem;
  }
  &__title {
    margin-top: 0;
    @include tablet {
      font-size: 2rem;
    }
  }
  &__info{
    font-size: 1rem;
    @include tablet{
      font-size: 1.2rem;
    }
  }
  &__image-list{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}
