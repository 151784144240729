@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.Home {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 166px);
  @include tablet {
    padding: 0.5rem 2rem;
    min-height: calc(100vh - 203px);
  }
  @include desktop {
    min-height: calc(100vh - 241px);
  }
  &__title {
    font-size: 1.1rem;
    transition: 0.5s;
    @include tablet {
      font-size: 2rem;
    }
    @include desktop {
      font-size: 2.5rem;
    }
  }
  &__back {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100px;
    transition: 0.4s;
    background-position: center;
    &--WMU{
      background-image: url("../../Images/GeneralWebsiteArt/unmarked.png");
    }
    &--public{
      background-image: url("../../Images/GeneralWebsiteArt/landscape-trees-forest.jpg");
    }
    &--hunt{
      background-image: url("../../Images/GeneralWebsiteArt/huntingLandscape.jpg");
      background-size: cover;
    }
    &--fish{
      background-image: url("../../Images/GeneralWebsiteArt/fishingLayer.jpg");
      background-size: cover;
    }
    &-wrapper {
      position: absolute;
      z-index: .4;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      transition: 1s;
      &:hover{
        background-color: rgba(0, 0, 0, 0);
      }
    }
    @include tablet {
      height: 220px;
    }
    @include desktop {
      height: 300px;
    }
  }
  &__link{
    border: 2px solid black;
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    border-radius: 8px;
    box-shadow: 3px 3px 3px #888;
    @include tablet {
      box-shadow: 6px 6px 6px #888;
    }
  }
  &__link-wrapper {
    flex: 1;
    padding: 1rem;
  }
  &__content-wrapper{
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    @include desktop {
      flex-direction: row;
      flex-grow: 1;
      align-items: center;
    }
  }
}

* {
  text-decoration: none;
  color: black;
}
