@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.product-card {
  border: 1px solid black;
  width: 210px;
  margin: 1rem;
  padding: 0.5rem;
  padding-top: 0;
  border-radius: 4px;
  box-shadow: 2px 2px 2px #888;
  transition: .4s;
  @include tablet {
    width: 230px;
    max-width: 230px;
    height: 240px;
    max-height: 240px;
  }
  &:hover {
    .product-card__image {
      max-width: 120px;
      width: 120px;
    }
  }
  &__title {
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__image {
    transition: 0.4s;
    max-width: 100px;
  }
}
