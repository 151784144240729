@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.shop {
  padding: 1rem;
  display: flex;
  @include perfectHeight;
  &-nav {
    &__btn {
      display: none;
      @include tablet {
        display: block;
        width: 130px;
        background-color: white;
        border: none;
        border-top: 1px solid black;
        padding: 0.4rem 0.2rem;
        font-size: 1.2rem;
        transition: 0.4s;
        text-align: left;
      }
      &:hover {
        cursor: pointer;
        background-color: #ddd;
      }
      &:last-child {
        border-bottom: 1px solid black;
      }
    }
    margin-right: 1rem;
  }
  &-main {
      @include tablet{
          margin-left: 2rem;
      }
    &__product-list {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
    &__title {
      margin-top: 0;
    }
  }
}
