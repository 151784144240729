@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/variables";


.AdsPage {
    @include perfectHeight;
    display: flex;
    &__filters {
        &-filter{
            display: flex;
            justify-content: space-between;
        }
    }
}