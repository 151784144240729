@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.foot {
  background-color: green;
  background-image: linear-gradient(315deg, green 0%, #2d9600 74%);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: 0.4s;
  @include tablet {
    padding: 1rem 1.5rem;
  }
  @include desktop {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 4rem;
  }
  &__title-wrapper {
    display: flex;
  }
  &__title {
    color: white;
    font-family: wilderness;
    font-weight: 100;
    font-size: 2rem;
    transition: 0.4s;
    @include tablet {
      font-size: 3rem;
    }
    @include desktop {
      font-size: 4rem;
    }
    margin: 0;
  }
  &__p {
    color: white;
    font-size: 0.4rem;
    transition: 0.4s;
    margin: 0;
    @include tablet {
      font-size: 1rem;
    }
  }
  &__social {
    display: flex;
    flex-direction: column;
  }
  &__icon {
    height: 26px;
    margin-right: 0.2rem;
  }
  &__anchor {
    margin-top: 0.6rem;
    display: flex;
    align-items: center;
    color: white;
  }
  &__feedback {
    background-color: white;
    font-size: 1rem;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem;
    border: 3px solid black;
    box-shadow: 4px 4px 4px black;
    transition: 0.2s;
    &:hover {
      cursor: pointer;
      box-shadow: 1px 1px 1px black;
    }
  }
  &__overlay {
    position: fixed;
    border: 4px solid black;
    border-radius: 1rem;
    width: 80vw;
    height: fit-content;
    top: 10vw;
    left: 10vw;
    @include desktop {
      width: 50vw;
      height: fit-content;
      left: 25vw;
    }
  }
  &__rel {
    position: relative;
    height: 100%;
  }
}

.overlay {
  &__title {
    text-align: center;
    margin-top: 0;
  }
  &__wrapper {
    height: 100%;
    width: 100%;
    padding: 1rem;
  }
  &__form {
    padding: 0 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.4s;
    @include tablet {
      flex-direction: row-reverse;
    }
  }
  &__label {
    font-size: 1.5rem;
  }
  &__input {
    margin-bottom: 1rem;
    font-size: 1rem;
    padding: 0.4rem;
  }
  &__message {
    margin-bottom: 1rem;
    padding: 0.3rem;
    font-size: 1rem;
    height: 14vh;
    resize: none;
  }
  &__submit {
    margin-bottom: 1rem;
    padding: 0.4rem;
    font-size: 1.1rem;
    background-color: rgb(3, 221, 3);
    border: none;
    box-shadow: 4px 4px 4px black;
    transition: 0.2s;
    border-radius: 0.3rem;
    transition: 0.4s;
    @include tablet {
      width: 45%;
    }
    &:hover {
      cursor: pointer;
      box-shadow: 1px 1px 1px black;
    }
    &--out {
      background-color: rgb(241, 33, 33);
    }
  }
  &__backWhite {
    background-color: white;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    display: block;
    border-radius: 1rem;
    z-index: -1;
    opacity: 0.9;
  }
}
