@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.animalCallingCard {
  &__title {
    margin: 0;
    @include tablet {
      margin-top: 1rem;
      font-size: 1.4rem;
    }
  }
  &__info {
    @include tablet {
      font-size: 1.2rem;
      margin-bottom: 0;
    }
  }
}
