@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.AnimalPresentation{
    max-width: 1400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: .5rem 2rem;
    border-bottom: 1px solid lightgrey;
    transition: .4s;
    &:hover{
        background-color: #eee;
    }
    @include tablet{
        flex-direction: row-reverse;
        align-items: flex-start;
    }
    &__info{
        width: 100%;
        @include desktop{
            font-size: 1.2rem;
        }
    }
    &__name{
        @include tablet{
            margin-top: 0;
        }
        @include desktop{
            font-size: 1.5rem;
        }
    }
    &__img{
        border-radius: 8px;
        border:2px solid black;
        @include tablet{
            margin-right: 1rem;
        }
       width: 200px;
       height: 200px; 
    }
}