@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.ParkPage {
  padding: 1rem;
  min-height: 78vh;
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
  }
  & div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #bbb;
    animation: lds-grid 1.2s linear infinite;
    @include tablet {
      width: 32px;
      height: 32px;
    }
  }
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
  @include tablet {
    top: -8px;
    left: -8px;
  }
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
  @include tablet {
    top: -8px;
    left: 32px;
  }
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
  @include tablet {
    top: -8px;
    left: 72px;
  }
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
  @include tablet {
    top: 32px;
    left: -8px;
  }
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
  @include tablet {
    top: 32px;
    left: 32px;
  }
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
  @include tablet {
    top: 32px;
    left: 72px;
  }
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
  @include tablet {
    top: 72px;
    left: -8px;
  }
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
  @include tablet {
    top: 72px;
    left: 32px;
  }
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
  @include tablet {
    top: 72px;
    left: 72px;
  }
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
