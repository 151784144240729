@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.wmuTable {
  padding: 1rem 0;
  height: 1.8rem;
  font-size: 1rem;
  @include tablet {
    font-size: 1.1rem;
  }
  &:nth-child(even) {
    background: #ddd;
  }
  &__data {
    min-width: 70px;
    width: 200px;
    max-width: 300px;
  }
  &thead tr td {
    font-size: 1.2rem;
  }
  &__open {
    cursor: pointer;
  }
  &__overlay {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 300px;
    @include tablet {
      width: 500px;
      height: 500px;
    }
  }
}
