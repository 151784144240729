@import "../../Partials/variables";
@import "../../Partials/mixins";
@import "../../Partials/fonts";

.animalGear{
    margin-top: 2rem;
    @include animalCardStyle;
    @include tablet{
        padding: 2rem 4rem;
    }
    &__title{
        margin-top: 0;
        @include tablet{
            font-size: 2rem;
        }
    }
}